import ChassisTable from "./containers/ChassisList/ChassisTable";
import NotificationAlert from "./components/SnackBar/Snackbar";
import { TableRowProvider } from "./context/TableRowContext";
import { SnackBarProvider } from "./context/SnackBarContext";
import "./root.css";
export default function Root() {
  return (
    <section className="AgreementList-Root-container">
      <section style={{ height: "100%" }}>
        <TableRowProvider>
          <SnackBarProvider>
            <ChassisTable />
            <NotificationAlert />
          </SnackBarProvider>
        </TableRowProvider>
      </section>
    </section>
  );
}
