function formatDate(date) {
  return date.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
}
function groupByCarrierAndDay(chassisHistory) {
  let listOfChassis = {};
  chassisHistory.forEach((chassis) => {
    if (!listOfChassis[chassis.CarrierScacCode]) {
      listOfChassis[chassis.CarrierScacCode] = {};
    }
    if (!listOfChassis[chassis.CarrierScacCode][chassis.Day]) {
      listOfChassis[chassis.CarrierScacCode][chassis.Day] = {
        RentedChassisCount: 0,
        Details: [],
      };
    }
    listOfChassis[chassis.CarrierScacCode][chassis.Day].RentedChassisCount +=
      chassis.RentedChassisCount;
    listOfChassis[chassis.CarrierScacCode][chassis.Day].Details.push(
      ...chassis.Details
    );
  });
  return listOfChassis;
}

function convertToListFormat(groupedData) {
  let resultList = [];
  for (const carrier in groupedData) {
    for (const day in groupedData[carrier]) {
      resultList.push({
        ScacCode: carrier,
        Day: formatDate(day),
        RentedChassisCount: groupedData[carrier][day].RentedChassisCount,
        Details: groupedData[carrier][day].Details,
      });
    }
  }
  return resultList;
}

export function setModelToHistory(billDayList) {
  const groupedByCarrier = groupByCarrierAndDay(billDayList);
  const finalResult = convertToListFormat(groupedByCarrier);
  return finalResult;
}
