import React from "react";
import { Typography, Paper, Checkbox, CircularProgress } from "@mui/material";

import { GetHistoryList } from "../../services/HistoryService";
import { CustomNoRowsOverlay } from "../../components/TableNoRowsOvlery";
import { setModelToHistory } from "../../models/HistoryModel";
import Cookies from "universal-cookie";
import DataTable from "react-data-table-component";
import "./ChassisTable.css";
import FiltersChassisHistory from "../FiltersChassisHistory/FiltersChassisHistory";
import dayjs from "dayjs";
const ChassisTable = () => {
  const [yardOwners, setYardOwnerList] = React.useState({
    allRows: [],
    rows: [],
  });
  const [filters, setFilters] = React.useState({
    search: "",
    startDate: dayjs(new Date()).subtract(7, "day"),
    endDate: dayjs(new Date()),
  });

  const cookies = new Cookies();
  const [fetchingData, setFetchingData] = React.useState(false);

  const [quantityOfChassisRentedSummary, setQuantityOfChassisRentedSummary] =
    React.useState(0);

  const [selectedRows, setSelectedRows] = React.useState(false);

  const allColumns = [
    {
      name: "Rented By",
      sortable: true,
      center: true,
      selector: (row) => row.ScacCode,
    },
    {
      name: "Date",
      sortable: true,
      center: true,
      selector: (row) => row.Day,
    },

    {
      name: "Quantity",
      sortable: true,
      center: true,
      selector: (row) => row.RentedChassisCount,
    },
  ];

  function handleFormatDateToYYYYMMDD(date) {
    const newDate = new Date(date);

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function handleFetchData() {
    setFetchingData(true);
    const sessionCosmos = cookies.get("sessionCosmos");
    if (!sessionCosmos) {
      window.addEventListener("userInB2C", () => {
        handleFetchData();
      });
      return;
    }
    handleGetHistory(
      handleFormatDateToYYYYMMDD(filters.startDate),
      handleFormatDateToYYYYMMDD(filters.endDate)
    );
  }

  function handleGetHistory(start, end) {
    GetHistoryList(start, end).then((response) => {
      const dataList = setModelToHistory(response.Data);

      setYardOwnerList({
        allRows: dataList,
        rows: dataList,
      });
      setFetchingData(false);
    });
  }

  React.useEffect(() => {
    handleFetchData();
  }, [filters.startDate, filters.endDate]);

  const expandedComponent = ({ data }) => (
    <Paper elevation={3} className="ChassisHistory-chassis-detail-paper">
      <Typography variant="h6" className="ChassisHistory-chassis-detail-title">
        Chassis Detail
      </Typography>{" "}
      <div className="ChassisHistory-YardOwnerTable-secondary-table-class">
        <DataTable
          columns={[
            {
              name: "Owner",
              sortable: true,
              center: true,
              selector: (row) => row.ScacCodeOwner,
            },
            {
              name: "Chassis Number",
              sortable: true,
              center: true,
              selector: (row) => row.ChassisNumber,
            },
            {
              name: "Chassis Type",
              sortable: true,
              center: true,
              selector: (row) => row.Type,
            },
          ]}
          pagination
          responsive
          highlightOnHover
          data={data.Details}
          fixedHeader
          striped
        />
      </div>
    </Paper>
  );

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const calculateChassis = () => {
    let selectedChassisSummary = 0;
    if (!selectedRows) {
      setQuantityOfChassisRentedSummary(0);
      return;
    }
    selectedRows.map((selectedRow) => {
      selectedChassisSummary += selectedRow.RentedChassisCount;
    });
    setQuantityOfChassisRentedSummary(selectedChassisSummary);
  };

  function handleFilterRowsBySearch() {
    const lowerCaseSearch = filters.search.toLowerCase();

    const filteredData = yardOwners.allRows.filter((row) => {
      if (
        row.ScacCode.toLowerCase().includes(lowerCaseSearch) ||
        row.RentedChassisCount.toString().includes(lowerCaseSearch)
      ) {
        return true;
      }
      return filteredData;
    });

    setYardOwnerList((prevYardOwners) => ({
      ...prevYardOwners,
      rows: filteredData,
    }));
  }

  React.useEffect(() => {
    calculateChassis();
  }, [selectedRows]);

  React.useEffect(() => {
    handleFilterRowsBySearch();
  }, [filters.search]);

  return (
    <>
      <section className="ChassisHistory-YardOwnerTable-container">
        <FiltersChassisHistory
          filters={filters}
          setFilters={setFilters}
          quantityOfChassisRentedSummary={quantityOfChassisRentedSummary}
        />
        <Typography
          variant="h5"
          className="ChassisHistory-YardOwnerTable-table-title"
        >
          Chassis History
        </Typography>
        <Paper
          elevation={3}
          className="ChassisHistory-YardOwnerTable-table-class"
        >
          {fetchingData ? (
            <div className="ChassisHistory-YardOwnerTable-table-loading">
              <CircularProgress
                className="ChassisHistory-YardOwnerTable-circular-loading"
                thickness={5}
              />
            </div>
          ) : (
            <div className="ChassisHistory-YardOwnerTable-table-class">
              <DataTable
                selectableRows
                selectableRowsComponent={Checkbox}
                columns={allColumns}
                data={yardOwners.rows}
                expandableRows
                onSelectedRowsChange={handleSelectedRowsChange}
                expandableRowsComponent={expandedComponent}
                fixedHeader
                striped
                selectAllRowsItem={true}
                pagination
                responsive
                highlightOnHover
                style={{
                  minHeight: "95%",
                }}
              />
            </div>
          )}
        </Paper>
      </section>
    </>
  );
};

export default ChassisTable;
