import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./FiltersChassisHistory.css";

const FiltersChassisHistory = ({
  filters,
  setFilters,
  quantityOfChassisRentedSummary,
}) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        className="ChassisHistory-Filters-Grid-Container"
      >
        <Grid xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            label="Search"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              sx={{ width: "100%" }}
              maxDate={filters.endDate}
              inputFormat="DD/MM/YYYY"
              value={filters.startDate}
              onChange={(date) =>
                setFilters({ ...filters, startDate: dayjs(date) })
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              sx={{ width: "100%" }}
              inputFormat="DD/MM/YYYY"
              minDate={filters.startDate}
              value={filters.endDate}
              onChange={(date) =>
                setFilters({ ...filters, endDate: dayjs(date) })
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={3}
          className="ChassisHistory-FilterChassisHistory-quantity-chassis-grid"
        >
          <Typography
            variant="h6"
            className="ChassisHistory-FilterChassisHistory-quantity-chassis-grid"
          >
            Chassis Quantity Selected:{" "}
          </Typography>
          <Typography variant="h3" className="">
            {quantityOfChassisRentedSummary}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersChassisHistory;
