// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .AgreementList-Root-container {
    height: calc(100vh - 72px);
}`, "",{"version":3,"sources":["webpack://./src/root.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B","sourcesContent":["body .AgreementList-Root-container {\n    height: calc(100vh - 72px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
